* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; // Remove tap highlight on mobile
  touch-action: manipulation; // Improve touch experience
}

html, body {
  overflow-x: hidden; // Prevent horizontal scrolling on mobile
}

.app {
  font-family: 'Avenir', 'Lato', 'Roboto', sans-serif;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: stretch;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2+ */
  color: #f9f9f9;
  margin: 0;
  
  // Add safe area insets for mobile devices with notches
  padding: env(safe-area-inset-top, 0) 
           env(safe-area-inset-right, 0) 
           env(safe-area-inset-bottom, 0) 
           env(safe-area-inset-left, 0);
           
  // Very dark navy blue (almost black) background
  background: #040b1a !important;
}

// Add the gradient pulse animation
@keyframes gradientPulse {
  0% { opacity: 0.85; }
  50% { opacity: 0.7; }
  100% { opacity: 0.85; }
}

.heading {
  font-weight: 700;
  letter-spacing: -0.5px;
  
  @media (max-width: 576px) {
    font-size: 1.5rem; // Smaller heading on mobile
  }
}

.subheading {
  font-weight: 400;
  letter-spacing: 0.2px;
  
  @media (max-width: 576px) {
    font-size: 0.9rem; // Smaller subheading on mobile
  }
}

.banner--fadeBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70vh; /* Much taller - covers 70% of the viewport height */
  width: 100%;
  pointer-events: none;
  background: -webkit-linear-gradient(top, rgba(4, 11, 26, 0) 0%, rgba(4, 11, 26, 0.4) 30%, rgba(4, 11, 26, 0.7) 60%, rgba(4, 11, 26, 0.9) 80%, rgba(4, 11, 26, 1) 100%);
  background: linear-gradient(to bottom, rgba(4, 11, 26, 0) 0%, rgba(4, 11, 26, 0.4) 30%, rgba(4, 11, 26, 0.7) 60%, rgba(4, 11, 26, 0.9) 80%, rgba(4, 11, 26, 1) 100%);
  
  @media (max-width: 576px) {
    height: 80vh; /* Even taller on mobile */
  }
}

/* Add a top fade to complement the bottom fade */
.banner--fadeTop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50vh; /* Increased height for better coverage */
  width: 100%;
  pointer-events: none;
  z-index: 1; /* Ensure it's above the base banner but below content */
  background: -webkit-linear-gradient(bottom, rgba(4, 11, 26, 0) 0%, rgba(4, 11, 26, 0) 40%, rgba(4, 11, 26, 0.05) 60%, rgba(4, 11, 26, 0.2) 75%, rgba(4, 11, 26, 0.4) 90%, rgba(4, 11, 26, 0.6) 100%);
  background: linear-gradient(to top, rgba(4, 11, 26, 0) 0%, rgba(4, 11, 26, 0) 40%, rgba(4, 11, 26, 0.05) 60%, rgba(4, 11, 26, 0.2) 75%, rgba(4, 11, 26, 0.4) 90%, rgba(4, 11, 26, 0.6) 100%);
  
  @media (max-width: 576px) {
    height: 60vh; /* Taller on mobile */
  }
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Disney+ like subtle glow effect for focused elements
@keyframes focusGlow {
  0% { box-shadow: 0 0 0 rgba(58, 129, 246, 0); }
  50% { box-shadow: 0 0 15px rgba(58, 129, 246, 0.5); }
  100% { box-shadow: 0 0 0 rgba(58, 129, 246, 0); }
}

.focus-glow:hover {
  animation: focusGlow 2s infinite;
}

.placeholder-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  color: white;
  font-size: 2rem;
  font-weight: 600;
  background-color: transparent;
  padding-top: 70px;
  text-align: center;
  
  @media (max-width: 576px) {
    font-size: 1.5rem;
    padding: 60px 20px 0;
    min-height: 70vh;
  }
}
