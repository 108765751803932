.error-banner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  background-color: rgba(220, 53, 69, 0.95);
  color: white;
  padding: 2rem;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  max-width: 90%;
  width: 500px;
  
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  &__emoji {
    font-size: 4rem;
    margin-bottom: 1rem;
    animation: pulse 2s infinite ease-in-out;
  }
  
  h3 {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  p {
    margin: 0;
    font-size: 1rem;
    opacity: 0.9;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} 