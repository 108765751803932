@import url('https://fonts.googleapis.com/css2?family=Avenir:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

/* Base styles with performance optimizations */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  font-family: 'Avenir', 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #040b1a; /* Very dark navy blue (almost black) */
  color: #f9f9f9;
  overflow-x: hidden;
  overscroll-behavior: none;
  text-rendering: optimizeSpeed; /* Optimize for speed over precision */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
  height: 100%;
  min-height: 100vh;
  position: relative;
}

/* Stop browser paint flickering during scroll */
html {
  scroll-behavior: smooth;
  height: 100%;
}

/* Add global background overlay to ensure gradient is always visible */
body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5; /* Behind everything but still visible */
  background: #040b1a !important; /* Very dark navy blue (almost black) */
  pointer-events: none; /* Don't block interactions */
}

body {
  height: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Improved scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.5);
}

/* Disney+ style focus outline */
:focus {
  outline: 2px solid #0072d2;
  outline-offset: 2px;
}

/* Disney+ style selection */
::selection {
  background-color: rgba(0, 114, 210, 0.3);
  color: #fff;
}

/* Performance optimizations for smoother animations */
.optimize-gpu {
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  isolation: isolate;
}

/* Add CSS containment for better performance */
.contain-layout {
  contain: layout;
}

.contain-paint {
  contain: paint;
}

.contain-strict {
  contain: strict;
}

/* Add content visibility for better performance */
.contain-content {
  content-visibility: auto;
  contain-intrinsic-size: 300px 200px;
}

/* Force hardware acceleration for all interactive elements by default */
button, 
a, 
.movie-card, 
.search-input,
.search-button,
.card-button,
.thumbnail,
.card-overlay,
.preview-player-container,
.preview-expand-button {
  transform: translateZ(0);
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

/* Optimize hover animations by preparing them */
button:before, 
a:before, 
.movie-card:before, 
.card-button:before,
.modal-play-button:before,
.preview-expand-button:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

/* Force hardware acceleration for transitions */
@media (prefers-reduced-motion: no-preference) {
  .card-button,
  .preview-expand-button,
  .movie-card,
  .modal-play-button,
  .close-button,
  .player-close-button,
  button {
    transform: translateZ(0);
    will-change: transform;
    backface-visibility: hidden;
    /* Add cubic-bezier transitions directly at global level */
    transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1) !important;
  }
  
  /* Smoother transition curve for most animations */
  .search-container button,
  .movie-card,
  .card-overlay {
    transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1) !important;
  }
  
  /* Pre-hint to browser about hover states */
  .movie-card:hover,
  button:hover,
  .card-button:hover,
  .preview-expand-button:hover,
  .modal-play-button:hover {
    transform: scale(calc(1 + var(--scale, 0.03))) translateZ(0);
  }
}

/* For browsers that support it, enable enhanced GPU rendering */
@supports (content-visibility: auto) {
  .results-grid {
    content-visibility: auto;
    contain-intrinsic-size: 300px;
  }
  
  .movie-card {
    content-visibility: auto;
    contain-intrinsic-size: 300px 200px;
  }
}

/* Force hardware acceleration and prevent browser overrides for YouTube player */
.embedded-youtube-player,
.search-player-wrapper,
.search-player-wrapper iframe,
.inline-youtube-player,
.direct-youtube-embed {
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform, opacity;
  z-index: 999 !important;
}

.search-player-wrapper.force-visible,
.search-player-wrapper.visible,
.search-movie-modal.youtube-playing .search-player-wrapper {
  opacity: 1 !important;
  visibility: visible !important;
  display: block !important;
  transform: translateZ(0);
}

/* Fix for browsers that might hide iframes */
iframe[src*="youtube.com"] {
  visibility: visible !important;
  opacity: 1 !important;
  display: block !important;
}

/* Fix for Safari */
@supports (-webkit-overflow-scrolling: touch) {
  .search-player-wrapper {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  
  /* Safari specific optimizations for animations */
  .movie-card,
  .card-button,
  .preview-expand-button,
  .thumbnail {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}

/* Fix for Firefox */
@-moz-document url-prefix() {
  .movie-card,
  .card-button,
  .preview-expand-button {
    transform: translate3d(0, 0, 0);
    will-change: transform;
  }
}

/* Animation optimizations for Chrome */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .movie-card:hover,
  .card-button:hover {
    transform: translate3d(0, 0, 0) scale(var(--scale, 1.03));
  }
}

/* Reduce animations for browsers with low resources */
@media (prefers-reduced-motion), (max-resolution: 1dppx) {
  .movie-card,
  .card-button,
  .preview-expand-button,
  .thumbnail {
    transition: opacity 0.2s ease !important;
    transform: none !important;
    will-change: opacity !important;
  }
  
  .movie-card:hover {
    transform: none !important;
  }
  
  .thumbnail {
    transition: none !important;
  }
}
